<template>
	<div id="ContactUs">
		联系我们
	</div>
</template>

<script>
	export default {
		name: 'ContactUs',
		components: {},
		data() {
			return {}
		},
		created() {},
		mounted() {},
		methods: {}
	}
</script>

<style lang="less" scoped>

</style>